import React, { useCallback, useEffect, useState } from 'react';
import { get_data, loading, refreshToken, setAlerts, getDictToParamsUrl } from '../../AppUtils';
import { Navigate } from 'react-router-dom';
import { ReaSelect } from '../Utils/ReaSelect';
import { useGlobalState } from '../GlobalContext';

const StSearch = (props) => {
    const [gState, setGState] = useGlobalState();

    const [city, setCity_] = useState(null);
    const [stOpts, setStOpts] = useState(null);
    const [stSearchOpts, setStSearchOpts] = useState(null);
    const [stProject, setStProject] = useState(null);
    const [nextTo, setNextTo] = useState(null);


    const setCity = (val) => {
        if (val && val !== city) {
            loading()
            let body_ = {
                City: val,
            }

            let reqOpts = {
                ...refreshToken(),
                body: JSON.stringify(body_),
            }

            get_data(process.env.REACT_APP_BACKEND_URL + "getSTSearchOpts", reqOpts).then(data => {
                data && setStOpts(data?.req_projects);

                // Grouped options for projects and locations
                let groupedOptions = [
                    {
                        label: "Project Name(s)",
                        options: data?.req_projects?.projects?.map(ele => ({
                            label: ele,
                            value: ele,
                        }))
                    },
                    {
                        label: "Locality",
                        options: data?.req_projects?.locations?.map(ele => ({
                            label: ele,
                            value: ele,
                        }))
                    }
                ];

                setStSearchOpts(groupedOptions);

            }).catch(err => {
                setStSearchOpts(null);
                setAlerts("error", err);
            }).finally(() => {
                loading(false);
            });
        }

        setCity_(val);
    }


    const onSubmit = useCallback((ev = null, hmStSearch = null) => {
        ev?.preventDefault();
        if ((city && stProject) || hmStSearch) {

            let body = hmStSearch ? {
                City: hmStSearch?.city,
                selection: hmStSearch?.projectName,
                selectionOf: hmStSearch?.selectionOf,
            } : {
                City: city,
                selection: stProject?.value,
                selectionOf: stOpts?.projects?.includes(stProject?.value) ? "projects" : "locations",
            };

            setGState({
                hmStSearch: null,
                searchForStProj: body,
                resultForSalTra: null,
            });

            let url = getDictToParamsUrl("/st/list/", body);

            setNextTo(url);

        } else {
            setAlerts("error", "Please search the Project/Locality...");
        }
    }, [city, setGState, stOpts?.projects, stProject]);


    useEffect(() => {
        if (gState?.hmStSearch) {
            onSubmit(null, gState?.hmStSearch);
        }
    }, [gState?.hmStSearch, onSubmit]);

    useEffect(() => {
        let foSep = document?.getElementById("id_footer_separator");
        foSep && !foSep?.classList?.contains("bg-secondary") && foSep?.classList?.add("bg-secondary");

        return () => {
            let foSep = document?.getElementById("id_footer_separator");
            foSep && foSep?.classList?.contains("bg-secondary") && foSep?.classList?.remove("bg-secondary");
        }

    }, []);



    return (nextTo ? <Navigate to={nextTo} /> : <>
        <div className='bg-secondary row justify-content-center' style={{ minHeight: '100vh' }}>
            {/* <!-- Header and footer --> */}
            <div className="col-md-7 col-xl-4 my-auto card text-center">
                <div className="card-header">
                    <h4 className="card-title text-primary mb-0">Sale Transactions</h4>
                </div>
                <div className="card-body">
                    <div className="card-text">
                        <form id='id_st_search_form' method='POST' onSubmit={(ev) => onSubmit(ev)}>
                            <label className='mb-1'>
                                <b>
                                    City
                                </b>
                            </label>
                            <ReaSelect
                                {...props}
                                isDisabled={false}
                                value={city && {
                                    "label": city,
                                    "value": city
                                }}
                                onChange={(val) => {
                                    setCity(val?.value);
                                }}
                                options={[
                                    {
                                        label: "Pune", value: "Pune"
                                    },
                                ]}
                                // isRequired={!(custom_lat && custom_lng)}

                                border={true}
                                maxHeight={`38px`}
                                placeholder="-- Select City --"

                            />

                            <label className='mb-1 mt-4'>
                                <b>
                                    Project
                                    <br />
                                    <small>
                                        * For which Sale Transaction is required.
                                    </small>
                                </b>
                            </label>

                            <ReaSelect
                                {...props}
                                value={stProject}
                                onChange={(selOpts) => setStProject(selOpts)}
                                options={stSearchOpts}
                                placeholder="Select a Project or Village..."
                                isDisabled={city && stSearchOpts ? false : true}
                                border={true}
                            />

                        </form>
                    </div>
                    <button type='submit' form='id_st_search_form' className="btn btn-primary">Get Sale Transaction(s)</button>
                </div>
            </div>
        </div>
    </>
    )
}

export default StSearch;
